/* eslint-disable react/prop-types */
import { DrivenBox, DrivenTypography } from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import {
	captureCustomDataLayerEvent,
	GatsbyLinkBehavior as GatsbyLink,
} from './gatsby-link-behavior';

const ArticleThumbnail: any = ({
	image,
	alt,
}: {
	image?: any;
	alt?: string;
}) => {
	if (image?.localFile?.childImageSharp) {
		const gatsbyImage = getImage(
			image.localFile.childImageSharp.gatsbyImageData
		);

		return <GatsbyImage image={gatsbyImage} alt={alt || ''} />;
	}

	return (
		<StaticImage
			src={'../../static/assets/news-thumbnail-fallback.svg'}
			width={800}
			height={600}
			alt=''
		/>
	);
};

interface ArticleCardProps {
	article: any;
	dataLayerEventName?: string;
}

export default function ArticleCard({
	article,
	dataLayerEventName,
}: ArticleCardProps) {
	const theme = useTheme();

	return (
		<DrivenBox
			component='article'
			key={`latest-articles-grid-${article.id}`}
			display='flex'
			flexDirection='column'
			alignItems='stretch'
			width='100%'
			marginX={0}
			marginTop={0}
			padding={0}
			paddingBottom={1}
			sx={{
				a: {
					textDecoration: 'none',
					color: theme.palette.common.black,

					'&:hover': {
						'.MuiTypography-h6': {
							color: theme.palette.tertiary?.main,
						},
					},
				},
			}}
		>
			<GatsbyLink
				href={article.path}
				onClick={
					dataLayerEventName
						? (e) => {
								captureCustomDataLayerEvent(dataLayerEventName, {
									article_title: article.headline,
									article_url: article.path,
								});
						  }
						: undefined
				}
			>
				<ArticleThumbnail image={article.image} alt={article.imageAlt} />
				<DrivenBox display='flex' flexDirection='column-reverse' mt={1}>
					<DrivenTypography variant='h6' component='h3'>
						{article.headline}
					</DrivenTypography>

					<DrivenTypography variant='body2' component='p' mb={0.5}>
						<time dateTime={article.dateStructured}>
							{article.dateFormatted}
						</time>
					</DrivenTypography>
				</DrivenBox>
			</GatsbyLink>
		</DrivenBox>
	);
}
