/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenIcon,
	DrivenLink,
	IconSizes,
	IconTypes,
} from '@iterateai/driven-components';
import MarkdownContent from './markdown-content';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';
import Heading, { HeadingProps } from './heading';
import * as React from 'react';
import { styled, useTheme } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

type Link = {
	href: string;
	text: string;
};

interface ContentSectionProps {
	body?: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	heading?: HeadingProps;
	image?: {
		title: string;
		publicUrl: string;
	};
	imageAlt?: string;
	imageAlign?: 'left' | 'right' | 'stacked' | undefined | null;
	actions?: Link[];
}

const ContentSectionImage: any = ({ image, alt }: any) => {
	const gatsbyImage = getImage(
		image?.localFile?.childImageSharp.gatsbyImageData
	);

	if (!gatsbyImage) {
		return;
	}

	return <GatsbyImage image={gatsbyImage} alt={alt || ''} />;
};

const StyledGridContainer = styled('section')(
	({
		theme,
		imageAlign,
	}: {
		theme: any;
		imageAlign: ContentSectionProps['imageAlign'];
	} & ContentSectionProps) => {
		const desktopAlignmentSettings = {
			gridTemplateAreas: `
			'image'
			'content'
		`,
			contentPaddingRight: '0',
			contentPaddingLeft: '0',
		};
		let gridTemplateAreasDesktop = '';

		switch (imageAlign) {
			case 'left':
				desktopAlignmentSettings.gridTemplateAreas = `'image content'`;
				desktopAlignmentSettings.contentPaddingLeft = theme.spacing(3);
				break;

			case 'right':
				desktopAlignmentSettings.gridTemplateAreas = `'content image'`;
				desktopAlignmentSettings.contentPaddingRight = theme.spacing(3);
				break;

			default:
				break;
		}
		return {
			display: 'grid',
			gridTemplateColumns: '1fr',
			gridTemplateAreas: `
			'image'
			'content'
		`,

			// Mobile
			[theme.breakpoints.only('xs')]: {
				marginBottom: theme.spacing(4),
			},

			// Tablet
			[theme.breakpoints.only('sm')]: {
				marginBottom: theme.spacing(4),
			},

			// Small desktop
			[theme.breakpoints.up('md')]: {
				gridTemplateColumns: '1fr 1fr',
				gridTemplateAreas: desktopAlignmentSettings.gridTemplateAreas,
			},

			'.ContentSection__Media': {
				gridArea: 'image',

				img: {
					display: 'block',
					width: '100%',
				},

				[theme.breakpoints.only('xs')]: {
					marginBottom: theme.spacing(2),
				},

				[theme.breakpoints.only('sm')]: {
					marginBottom: theme.spacing(2),
				},

				[theme.breakpoints.up('md')]: {
					display: 'flex',
				},
			},

			'.ContentSection__Content': {
				gridArea: 'content',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',

				[theme.breakpoints.up('md')]: {
					paddingTop: theme.spacing(8),
					paddingBottom: theme.spacing(8),
					paddingRight: desktopAlignmentSettings.contentPaddingRight,
					paddingLeft: desktopAlignmentSettings.contentPaddingLeft,
				},
			},
		};
	}
);

const StyledContentSectionLinkList = styled(DrivenBox)(({ theme }) => ({
	'.ContentSection__LinkList': {
		display: 'flex',
		flexWrap: 'wrap',
		rowGap: theme.spacing(1),
		columnGap: theme.spacing(2),
		width: '100%',
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},

	'.ContentSection__LinkListItem': {
		display: 'inline-block',
		margin: 0,
		padding: 0,
		whiteSpace: 'nowrap',
	},

	'.ContentSection__LinkListItem > *': {},
}));

const ContentSectionLinkList = ({ links }: { links: [Link] }) => {
	const theme = useTheme();

	if (links?.length) {
		return (
			<StyledContentSectionLinkList mt={2}>
				<ul className='ContentSection__LinkList'>
					{links.map((link: any) => {
						const href = link.page ? link.page.path : link.url;

						if (href) {
							return (
								<li className='ContentSection__LinkListItem'>
									<DrivenLink
										href={link.url}
										// icon={<DrivenIcon icon='arrow-right-long' />}
										// iconPlacement='right'
										color={theme.palette.tertiary?.main}
										variant='body2Bold'
										LinkComponent={GatsbyLink}
									>
										{link.text}{' '}
										<DrivenIcon icon='arrow-right-long' size={IconSizes.SM} />
									</DrivenLink>
								</li>
							);
						}

						return '';
					})}
				</ul>
			</StyledContentSectionLinkList>
		);
	}

	return null;
};

export default function ContentSection(props: ContentSectionProps) {
	const { body, heading, image, imageAlt, imageAlign, actions } = props;

	const links = actions?.map((link) => {
		return link;
	});

	const theme = useTheme();

	return (
		<DrivenBox>
			<StyledGridContainer theme={theme} imageAlign={imageAlign}>
				{image && (
					<DrivenBox gridTemplateArea='image' className='ContentSection__Media'>
						<ContentSectionImage image={image} alt={imageAlt} />
					</DrivenBox>
				)}

				<DrivenBox className='ContentSection__Content'>
					{heading && <Heading mb={1} {...heading} />}

					{body && (
						<MarkdownContent paragraphMarginY={0}>
							{body.childMarkdownRemark.rawMarkdownBody}
						</MarkdownContent>
					)}

					{links && <ContentSectionLinkList links={actions} />}
				</DrivenBox>
			</StyledGridContainer>
		</DrivenBox>
	);
}
