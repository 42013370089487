export const usStates = [
	{ value: '', option: ' ' },
	{ value: 'AL', option: 'Alabama' },
	{ value: 'AK', option: 'Alaska' },
	{ value: 'AZ', option: 'Arizona' },
	{ value: 'AR', option: 'Arkansas' },
	{ value: 'CA', option: 'California' },
	{ value: 'CO', option: 'Colorado' },
	{ value: 'CT', option: 'Connecticut' },
	{ value: 'DE', option: 'Delaware' },
	{ value: 'DC', option: 'District Of Columbia' },
	{ value: 'FL', option: 'Florida' },
	{ value: 'GA', option: 'Georgia' },
	{ value: 'HI', option: 'Hawaii' },
	{ value: 'ID', option: 'Idaho' },
	{ value: 'IL', option: 'Illinois' },
	{ value: 'IN', option: 'Indiana' },
	{ value: 'IA', option: 'Iowa' },
	{ value: 'KS', option: 'Kansas' },
	{ value: 'KY', option: 'Kentucky' },
	{ value: 'LA', option: 'Louisiana' },
	{ value: 'ME', option: 'Maine' },
	{ value: 'MD', option: 'Maryland' },
	{ value: 'MA', option: 'Massachusetts' },
	{ value: 'MI', option: 'Michigan' },
	{ value: 'MN', option: 'Minnesota' },
	{ value: 'MS', option: 'Mississippi' },
	{ value: 'MO', option: 'Missouri' },
	{ value: 'MT', option: 'Montana' },
	{ value: 'NE', option: 'Nebraska' },
	{ value: 'NV', option: 'Nevada' },
	{ value: 'NH', option: 'New Hampshire' },
	{ value: 'NJ', option: 'New Jersey' },
	{ value: 'NM', option: 'New Mexico' },
	{ value: 'NY', option: 'New York' },
	{ value: 'NC', option: 'North Carolina' },
	{ value: 'ND', option: 'North Dakota' },
	{ value: 'OH', option: 'Ohio' },
	{ value: 'OK', option: 'Oklahoma' },
	{ value: 'OR', option: 'Oregon' },
	{ value: 'PA', option: 'Pennsylvania' },
	{ value: 'RI', option: 'Rhode Island' },
	{ value: 'SC', option: 'South Carolina' },
	{ value: 'SD', option: 'South Dakota' },
	{ value: 'TN', option: 'Tennessee' },
	{ value: 'TX', option: 'Texas' },
	{ value: 'UT', option: 'Utah' },
	{ value: 'VT', option: 'Vermont' },
	{ value: 'VA', option: 'Virginia' },
	{ value: 'WA', option: 'Washington' },
	{ value: 'WV', option: 'West Virginia' },
	{ value: 'WI', option: 'Wisconsin' },
	{ value: 'WY', option: 'Wyoming' },
];

export const bestTimes = [
	{ value: '', option: '' },
	{ value: '8:00am - 9:00am', option: '8:00am - 9:00am' },
	{ value: '9:00am - 10:00am', option: '9:00am - 10:00am' },
	{ value: '10:00am - 11:00am', option: '10:00am - 11:00am' },
	{ value: '11:00am - 12:00pm', option: '11:00am - 12:00pm' },
	{ value: '12:00pm - 1:00pm', option: '12:00pm - 1:00pm' },
	{ value: '1:00pm - 2:00pm', option: '1:00pm - 2:00pm' },
	{ value: '2:00pm - 3:00pm', option: '2:00pm - 3:00pm' },
	{ value: '3:00pm - 4:00pm', option: '3:00pm - 4:00pm' },
	{ value: '4:00pm - 5:00pm', option: '4:00pm - 5:00pm' },
	{ value: '5:00pm - 6:00pm', option: '5:00pm - 6:00pm' },
	{ value: '6:00pm - 7:00pm', option: '6:00pm - 7:00pm' },
];
