import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormSchema } from '../utils';
import { BaseProps } from './';

type FormBaseProps = FormSchema & BaseProps;

// Created as an extension to the react-hook-form FormProvider
export const BaseContactForm = ({
	children,
	defaultValues,
	formOption,
	handleFormSubmit,
	resetValues = false,
	schema,
}: FormBaseProps) => {
	const methods = useForm({
		mode: 'all',
		criteriaMode: 'all',
		shouldFocusError: false,
		resolver: yupResolver(schema),
	});

	React.useEffect(() => {
		if (formOption) {
			methods.reset(defaultValues);
		}
	}, [formOption]);

	React.useEffect(() => {
		if (resetValues) {
			methods.reset(defaultValues);
		}
	}, [resetValues]);

	const formSubmit = (formData: any) => {
		handleFormSubmit(formData);
	};
	const formError = (error: any) => {
		console.log('form error ', error);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(formSubmit, formError)}>
				{children}
			</form>
		</FormProvider>
	);
};
