/* eslint-disable react/prop-types */
import { DrivenTabs, TextSize } from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import * as React from 'react';

import { ComponentMatch } from '../hooks/component-match';

interface TabsProps {}

export default function Tabs({ tabs }: TabsProps) {
	const [value, setValue] = React.useState(0);
	const theme = useTheme();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	if (tabs) {
		tabs.forEach((tab: any) => {
			tab.tabPanelContent = (
				<React.Fragment>{ComponentMatch([tab.content])}</React.Fragment>
			);
		});
	}

	return (
		<React.Fragment>
			<DrivenTabs
				tabs={tabs}
				tabVariant={TextSize.small}
				value={value}
				onChange={handleChange}
				// Horizontal scrolling for tabs.
				// TODO Edit DS component to not apply "center" alignment
				// when scrollable
				variant='scrollable'
				scrollButtons='auto'
			/>
		</React.Fragment>
	);
}
