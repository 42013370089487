/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenButton,
	DrivenButtonProps,
	DrivenContainer,
	DrivenGrid,
	DrivenTypography,
} from '@iterateai/driven-components';
import MarkdownContent from '../components/markdown-content';
import ContentfulButton from './button';
import { styled } from '@mui/material/styles';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

type StyledHeadingProps = {
	light?: string;
	bold?: string;
};

interface HeroProps {
	headingLight?: string;
	headingBold?: string;
	body?: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	media?: any;
	mediaAlt?: string;
	isMediaAlignedLeft?: boolean; // TODO make this have choices, not be a boolean
	darkMode?: boolean;
	actions?: DrivenButtonProps[];
}

const LightHeading = styled('span')(() => ({
	display: 'block',
	fontWeight: '400',
}));

const BoldHeading = styled('span')(() => ({
	display: 'block',
	fontWeight: '700',
}));

const StyledHeading = ({ light, bold }: StyledHeadingProps) => (
	<span>
		<LightHeading>{light}</LightHeading> <BoldHeading>{bold}</BoldHeading>
	</span>
);

const BackgroundImage = styled(GatsbyImage)(() => ({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,

	'&:after': {
		content: '""',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundImage:
			'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.2) 100%)',
	},
}));

const StyledBackgroundImage = ({ image }: any) => (
	<BackgroundImage image={image} alt='' loading='eager' />
);

export default function Hero({
	headingLight,
	headingBold,
	body,
	media,
	actions,
}: HeroProps) {
	const heroBg = getImage(media?.localFile.childImageSharp.gatsbyImageData);

	return (
		<DrivenBox position='relative'>
			{heroBg && <StyledBackgroundImage image={heroBg} />}
			<DrivenContainer maxWidth='lg'>
				<DrivenGrid container py={{ xs: 5, sm: 6, md: 8, xl: 24 }}>
					<DrivenGrid item sm={8} md={7}>
						<DrivenBox position='relative'>
							<DrivenTypography variant='h1' component='h1' color='white'>
								<StyledHeading light={headingLight} bold={headingBold} />
							</DrivenTypography>
							{body && (
								<DrivenTypography
									variant='body1'
									component='div'
									color='white'
									mt={3}
								>
									<MarkdownContent>
										{body.childMarkdownRemark.rawMarkdownBody}
									</MarkdownContent>
								</DrivenTypography>
							)}
							{actions && (
								<DrivenBox mt={6}>
									{actions.map((action, i) => (
										<ContentfulButton {...action} key={`${action.name}-${i}`} />
									))}
								</DrivenBox>
							)}
						</DrivenBox>
					</DrivenGrid>
				</DrivenGrid>
			</DrivenContainer>
		</DrivenBox>
	);
}
