/* eslint-disable react/prop-types */
import { DrivenBox, DrivenTypography } from '@iterateai/driven-components';
import * as React from 'react';

import Heading from './heading';
import MarkdownContent from './markdown-content';

export interface TestimonialProps {
	headingText: string;
	body: {
		childMarkdownRemark: {
			rawMarkdownBody: string;
			html: string;
		};
	};
	attribution?: string;
}

export default function Testimonial({
	headingText,
	body,
	attribution,
}: TestimonialProps) {
	return (
		<DrivenBox flexDirection='column'>
			<Heading level='h3' style='h5' text={headingText} />
			<DrivenBox mt={0.5}>
				<MarkdownContent paragraphMarginY='0'>
					{body?.childMarkdownRemark?.rawMarkdownBody}
				</MarkdownContent>
				{attribution && (
					<DrivenTypography
						variant='body1LightItalics'
						component='p'
						textAlign='right'
						mt={0.5}
					>
						{attribution}
					</DrivenTypography>
				)}
			</DrivenBox>
		</DrivenBox>
	);
}
