import {
	DrivenBox,
	DrivenButton,
	DrivenStack,
	DrivenTypography,
} from '@iterateai/driven-components';
import * as React from 'react';
import { FieldValues } from 'react-hook-form';
import { useMediaQuery, useTheme } from '@mui/material';

import { AccessibilityContactForm, GeneralContactForms } from '../';
import { dbAccessibilityServiceSchema, dbGeneralSchema } from '../utils';
import { BaseContactForm, BaseFormProps } from './';

const CenteredSubmitButton = () => {
	const theme = useTheme();
	const isMobileXs = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<DrivenBox display='flex' justifyContent='center' mt={3}>
			<DrivenButton
				type='submit'
				variant='outlined'
				color='inherit'
				fullWidth={isMobileXs ? true : false}
			>
				Submit
			</DrivenButton>
		</DrivenBox>
	);
};
// Use this component in order for nested forms to gain react-hook-form context
export const ContactFormWrapper = ({
	formOption,
	handleFormSubmit,
	resetValues = false,
}: BaseFormProps) => {
	const formValues = React.useMemo(() => {
		switch (formOption) {
			case 'Center':
			case 'Glass Center':
			case 'Car Wash':
				return dbGeneralSchema;
			default:
				return dbAccessibilityServiceSchema;
		}
	}, [formOption]);

	const renderFormOption = React.useCallback(
		(formOption?: string | number) => {
			let supportText = (
				<DrivenTypography variant='body1'>
					Fields marked with * are required.
				</DrivenTypography>
			);
			switch (formOption) {
				case 'Center':
				case 'Car Wash':
				case 'Glass Center':
					return (
						<React.Fragment>
							<GeneralContactForms supportText={supportText} />
							<CenteredSubmitButton />
						</React.Fragment>
					);
				default:
					return (
						<React.Fragment>
							<AccessibilityContactForm
								supportText={
									<DrivenStack spacing={3}>
										<DrivenTypography>
											Driven Brands, Inc. is committed to ensuring that persons
											with disabilities have access to Web pages, documents and
											information hosted on our websites.
										</DrivenTypography>
										<DrivenTypography>
											Please complete the form if you have any feedback or
											questions on the accessibility of our site.
										</DrivenTypography>
										{supportText}
									</DrivenStack>
								}
							/>
							<CenteredSubmitButton />
						</React.Fragment>
					);
			}
		},
		[formOption]
	);

	const handleFormSubmission = React.useCallback(
		(data: FieldValues) => {
			handleFormSubmit({ ...data });
		},
		[formOption]
	);

	return (
		<BaseContactForm
			defaultValues={formValues.defaultValues}
			formOption={formOption}
			handleFormSubmit={handleFormSubmission}
			resetValues={resetValues}
			schema={formValues.schema}
		>
			{renderFormOption(formOption)}
		</BaseContactForm>
	);
};
