import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';

type SEOProps = {
	path?: string;
	title: string;
	name?: string;
	description?: string;
	canonical?: string;
	noindex?: boolean;
	nofollow?: boolean;
	scriptSrc?: string;
	googleTagManager?: string;
	ogImage?: string;
	children?: React.ReactNode;
};

export const SEO: React.FC<SEOProps> = ({
																	 path = '',
																	 name = '',
																	 title,
																	 description = '',
																	 canonical = '',
																	 noindex = false,
																	 nofollow = false,
																	 scriptSrc,
																	 googleTagManager,
																	 ogImage,
																	 children,
																 }) => {
	const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata();

	const seoTitle = title || defaultTitle;
	const siteName = name || 'Driven Brands';
	const seoDescription = description || defaultDescription;
	const seoCanonical = canonical || new URL(path, siteUrl).href;
	const seoImage = ogImage ? new URL(ogImage, siteUrl).href : undefined; // Ensures absolute URL for the image
	const robotsMeta = `${noindex ? 'noindex' : 'index'},${nofollow ? 'nofollow' : 'follow'}`;

	// Dynamically create the GTM script using the provided GTM ID
	const gtmScript = googleTagManager ? `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManager}');
  ` : '';

	return (
		<>
			<title>{seoTitle}</title>
			<meta name="description" content={seoDescription} />
			{robotsMeta && <meta name="robots" content={robotsMeta} />}
			{seoCanonical && <link rel="canonical" href={seoCanonical} />}
			{scriptSrc && <script src={scriptSrc} async></script>}
			{gtmScript && <script dangerouslySetInnerHTML={{ __html: gtmScript }} />}

			{/* Open Graph Tags */}
			<meta property="og:title" content={seoTitle} />
			<meta property="og:description" content={seoDescription} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={seoCanonical} />
			<meta property="og:site_name" content={siteName} />
			{seoImage && <meta property="og:image" content={seoImage} />}

			{/* Additional Twitter Card Tags */}
			<meta name="twitter:card" content="summary_large_image" />
			{seoImage && <meta name="twitter:image" content={seoImage} />}
			<meta name="twitter:title" content={seoTitle} />
			<meta name="twitter:description" content={seoDescription} />

			{children}
		</>
	);
};