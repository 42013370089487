import * as yup from 'yup';

export type FormSchema = {
	defaultValues: {
		[key: string]: boolean | string | number | '' | null;
	};
	schema: yup.AnyObjectSchema;
};

// const emailRegex =
	// /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/gm;
	//add - to regex
	const emailRegex = /^[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/gm;

const phoneRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
export const matchesEmail = (input: string) => input.match(emailRegex);
export const matchesPhone = (input: string) => input.match(phoneRegex);
const requiredMessage = 'This field is required.';
// Reused yup schema
const firstName = yup.string().required(requiredMessage);
const lastName = yup.string().required(requiredMessage);
const time = yup.string().optional();
const address = yup.string().required(requiredMessage);
const address2 = yup.string().optional();
const city = yup.string().required(requiredMessage);
const state = yup.string().required(requiredMessage);
const message = yup.string().required(requiredMessage);
const email = yup
	.string()
	.matches(emailRegex, {
		message: 'Must be a valid email address',
		excludeEmptyString: true,
	})
	.required(requiredMessage);
const phone = yup
	.string()
	.matches(phoneRegex, {
		message: 'Must contain 10 digits',
		excludeEmptyString: true,
	})
	.required(requiredMessage);
const zipCode = yup
	.string()
	.test(
		'fiveDigits',
		'Must contain 5 digits',
		(value: any) => /\b\d{5}\b/.test(value) && value.length === 5
	)
	.required(requiredMessage);

// General Form
export const dbGeneralSchema: FormSchema = {
	defaultValues: {
		firstName: '',
		lastName: '',
		phone: '',
		time: '',
		email: '',
		streetAddress: '',
		streetAddress2: '',
		city: '',
		state: '',
		zipCode: '',
		businessName: '',
		annualSales: '',
		annualCarCount: '',
	},
	schema: yup.object({
		firstName: firstName,
		lastName: lastName,
		email,
		phone,
		time,
		streetAddress: address,
		streetAddress2: address2,
		city,
		state,
		zipCode: zipCode,
		businessName: yup.string().required(requiredMessage),
		annualSales: yup.number().required(requiredMessage),
		annualCarCount: yup.number().required(requiredMessage),
	}),
};

// Accessibility form
export const dbAccessibilityServiceSchema: FormSchema = {
	defaultValues: {
		firstName: '',
		lastName: '',
		email: '',
		comments: '',
	},
	schema: yup.object({
		firstName: firstName,
		lastName: lastName,
		email,
		comments: message,
	}),
};
