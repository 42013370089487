/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
	DrivenAccordion,
	DrivenBox,
	DrivenContainer,
	DrivenTypography,
} from '@iterateai/driven-components';
import { styled, useTheme } from '@mui/material';
import * as React from 'react';

type Link = {
	href: string;
	text: string;
};

type ContentfulAccordionItem = {
	id: string;
	title: string;
	body: {
		childMarkdownRemark: {
			rawMarkdownBody: string;
		};
	};
};

interface AccordionProps {
	__typename: string;
	links?: [Link];
	panelsOpen: [];
	records: ContentfulAccordionItem[];
	id: number;
	title: string;
	event: React.SyntheticEvent;
	isExpanded: boolean;
	faq?: boolean;
}

const StyledAccordion = styled(DrivenAccordion)(({ faq }) => {
	const theme = useTheme();

	const faqStyles = faq
		? {
				'.MuiAccordionSummary-content, .MuiCollapse-root': {
					display: 'flex',
					columnGap: theme.spacing(1),
					justifyContent: 'flex-start',
					alignItems: 'flex-start',

					'&:before': {
						...theme.typography.h5,
						flex: '32px 0 0',
					},
				},

				'.MuiAccordionSummary-content': {
					'&:before': {
						content: '"Q:"',
					},

					'.MuiTypography-button': {
						paddingTop: theme.spacing(0.5),

						...theme.typography.body1Bold,
					},
				},

				'.MuiCollapse-root': {
					'&:before': {
						content: '"A:"',
					},
				},
		  }
		: {};

	return faqStyles;
});

export default function Accordion(props: AccordionProps) {
	const [expanded, setExpanded] = React.useState(props.panelsOpen ?? []);

	const records = props?.records?.map((accordionItem) => {
		let result;
		result = {
			id: accordionItem.id ?? '',
			summary: accordionItem.title ?? '',
			details: (
				<DrivenTypography variant='body1' pt={0.5} pb={2}>
					{accordionItem.body?.childMarkdownRemark.rawMarkdownBody ?? ''}
				</DrivenTypography>
			),
		};

		return result;
	});

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenTypography variant={'h3'} fontSize={'48px'}>
				{props?.title}
			</DrivenTypography>

			{records && (
				<DrivenBox my={5}>
					<StyledAccordion
						faq={props.faq}
						handleChange={function handleChange(panelTriggered) {
							return function (event, isExpanded) {
								expanded.includes(panelTriggered)
									? setExpanded(
											expanded.filter(function (panel) {
												return panel !== panelTriggered;
											})
									  )
									: setExpanded([].concat(expanded, [panelTriggered]));
							};
						}}
						panelsOpen={expanded}
						records={records}
					/>
				</DrivenBox>
			)}
		</DrivenContainer>
	);
}
/* eslint-enable no-unused-vars */
