import {
	DrivenBox,
	DrivenDropdown,
	DrivenGrid,
	DrivenStack,
	DrivenTextInput,
	DrivenTypography,
} from '@iterateai/driven-components';
import { SelectChangeEvent, styled, useTheme } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ContactFormProps, formatPhoneNumber, usStates, bestTimes } from '../';

const StyledFieldset = styled('fieldset')(({ theme }) => ({
	margin: 0,
	padding: 0,
	border: 'none',

	legend: {
		margin: 0,
		padding: 0,
	},
}));

export const GeneralContactForms = React.memo(function GeneralContactForms({
	supportText,
}: ContactFormProps) {
	const [selectedState, setSelectedState] = React.useState('');
	const [selectedTime, setSelectedTime] = React.useState('');
	const {
		control,
		formState: { errors, isDirty },
		register,
	} = useFormContext();

	const handleUsStateChange = (event: SelectChangeEvent<unknown>) => {
		setSelectedState(event.target.value as string);
	};

	const handleTimeChange = (event: SelectChangeEvent<unknown>) => {
		setSelectedTime(event.target.value as string);
	};

	React.useEffect(() => {
		if (!isDirty) {
			setSelectedState('');
		}
	}, [isDirty]);

	const theme = useTheme();

	return (
		<DrivenBox>
			<DrivenStack>
				<DrivenTypography variant='body1' component='div' mb={3}>
					{supportText}
				</DrivenTypography>
			</DrivenStack>

			<DrivenStack spacing={3}>
				<StyledFieldset theme={theme}>
					<DrivenTypography variant='h6' component='legend'>
						Contact Information
					</DrivenTypography>

					<DrivenGrid container direction='column'>
						<DrivenGrid
							container
							item
							xs
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<DrivenGrid item xs={12} sm={6}>
								<DrivenTextInput
									label='First Name *'
									hasError={Boolean(errors['firstName'])}
									errorHelperText={errors['firstName']?.message as string}
									required
									{...register('firstName')}
								/>
							</DrivenGrid>
							<DrivenGrid item xs={12} sm={6}>
								<DrivenTextInput
									label='Last Name *'
									hasError={Boolean(errors['lastName'])}
									errorHelperText={errors['lastName']?.message as string}
									required
									{...register('lastName')}
								/>
							</DrivenGrid>
						</DrivenGrid>

						<DrivenGrid
							container
							item
							xs
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<DrivenGrid item xs={12} md={6}>
								<Controller
									control={control}
									name='phone'
									render={({ field: { onChange, name, value, ref } }: any) => (
										<DrivenTextInput
											label='Phone Number *'
											name={name}
											value={value ?? ''}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
												onChange(formatPhoneNumber(event.target.value))
											}
											hasError={Boolean(errors['phone'])}
											errorHelperText={errors['phone']?.message as string}
											ref={ref}
											required
										/>
									)}
								/>
							</DrivenGrid>
							<DrivenGrid item xs={12} md={6}>
								<DrivenDropdown
									label='Best Time to Call'
									options={bestTimes}
									value={selectedTime}
									{...register('time', {
										onChange: (event) => handleTimeChange(event),
									})}
								/>
							</DrivenGrid>
						</DrivenGrid>
						<DrivenGrid item xs>
							<DrivenTextInput
								label='Email Address *'
								hasError={Boolean(errors['email'])}
								errorHelperText={errors['email']?.message as string}
								required
								{...register('email')}
							/>
						</DrivenGrid>
					</DrivenGrid>
				</StyledFieldset>

				{/* Center Location */}
				<StyledFieldset theme={theme}>
					<DrivenTypography variant='h6' component='legend'>
						Center Location
					</DrivenTypography>

					<DrivenGrid container direction='column'>
						<DrivenGrid container item columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<DrivenTypography variant='h6'></DrivenTypography>
							<DrivenGrid item xs={12} md={6}>
								<DrivenTextInput
									label='Street Address *'
									hasError={Boolean(errors['streetAddress'])}
									errorHelperText={errors['streetAddress']?.message as string}
									required
									{...register('streetAddress')}
								/>
							</DrivenGrid>
							<DrivenGrid item xs={12} md={6}>
								<DrivenTextInput
									label='Street Address 2'
									{...register('streetAddress2')}
								/>
							</DrivenGrid>
						</DrivenGrid>
						<DrivenGrid
							container
							item
							xs
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<DrivenGrid item xs={12} md={4}>
								<DrivenTextInput
									label='City *'
									hasError={Boolean(errors['city'])}
									errorHelperText={errors['city']?.message as string}
									required
									{...register('city')}
								/>
							</DrivenGrid>
							<DrivenGrid item xs={12} md={4}>
								<DrivenDropdown
									label='State/Province *'
									// placeholder='Select One'
									options={usStates}
									hasError={Boolean(errors['state'])}
									errorHelperText={errors['state']?.message as string}
									value={selectedState}
									required
									{...register('state', {
										onChange: (event) => handleUsStateChange(event),
									})}
								/>
							</DrivenGrid>
							<DrivenGrid item xs={12} md={4}>
								<DrivenTextInput
									label='ZIP Code *'
									hasError={Boolean(errors['zipCode'])}
									errorHelperText={errors['zipCode']?.message as string}
									required
									{...register('zipCode')}
								/>
							</DrivenGrid>
						</DrivenGrid>
					</DrivenGrid>
				</StyledFieldset>

				{/* Center Information */}
				<StyledFieldset theme={theme}>
					<DrivenTypography variant='h6' component='legend'>
						Center Information
					</DrivenTypography>

					<DrivenGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<DrivenGrid item xs={12}>
							<DrivenTextInput
								label='Current Business Name *'
								hasError={Boolean(errors['businessName'])}
								errorHelperText={errors['businessName']?.message as string}
								{...register('businessName')}
							/>
						</DrivenGrid>

						<DrivenGrid item xs={12} sm={6}>
							<DrivenTextInput
								label='Annual Sales *'
								hasError={Boolean(errors['annualSales'])}
								errorHelperText={errors['annualSales']?.message as string}
								{...register('annualSales')}
							/>
						</DrivenGrid>

						<DrivenGrid item xs={12} sm={6}>
							<DrivenTextInput
								label='Annual Car Count *'
								hasError={Boolean(errors['annualCarCount'])}
								errorHelperText={errors['annualCarCount']?.message as string}
								{...register('annualCarCount')}
							/>
						</DrivenGrid>
					</DrivenGrid>
				</StyledFieldset>
			</DrivenStack>
		</DrivenBox>
	);
});
