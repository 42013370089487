/* eslint-disable react/prop-types */
import { DrivenBox, DrivenTypography } from '@iterateai/driven-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Heading from './heading';
import MarkdownContent from './markdown-content';

interface TeamMemberProps {}

const TeamMemberImage: any = ({ image }: { image?: any }) => {
	const gatsbyImage = getImage(image.gatsbyImageData);

	return (
		<DrivenBox mb={3} ml={{ sm: 3 }} sx={{ float: { sm: 'right' } }}>
			<GatsbyImage image={gatsbyImage} alt='' />
		</DrivenBox>
	);
};

export default function TeamMember({
	name,
	jobTitle,
	body,
	image,
}: TeamMemberProps) {
	return (
		<DrivenBox pt={3}>
			<TeamMemberImage image={image} />
			<Heading level='h3' style='subtitle1' text={name}></Heading>
			<DrivenTypography variant='subtitle2' component='p'>
				{jobTitle}
			</DrivenTypography>

			<MarkdownContent>
				{body.childMarkdownRemark.rawMarkdownBody}
			</MarkdownContent>
		</DrivenBox>
	);
}
