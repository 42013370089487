import {} from '@iterateai/driven-components';
import { graphql } from 'gatsby';
import React from 'react';
import { SEO } from '../components/seo';
import { ComponentMatch } from '../hooks/component-match';
import useIsClient from '../hooks/use-is-client';

const PageTemplate = ({ data }) => {
	const { isClient, key } = useIsClient();
	if (!isClient) return null;

	const {
		page: { blocks },
	} = data;

	return <React.Fragment>{ComponentMatch(blocks)}</React.Fragment>;
};

export default PageTemplate;

export const Head = ({ data, pageContext }) => (
	<SEO
		path={pageContext.pagePath}
		title={data.page?.title}
		description={data.page?.description}
		canonical={data.page?.canonicalUrl}
		noindex={data.page?.noindex}
		nofollow={data.page?.nofollow}
	/>
);

export const data = graphql`
	fragment LinkFields on ContentfulLink {
		__typename
		id
		contentful_id
		text
		page {
			__typename
			id
			contentful_id
			path
		}
		url
		icon
		target
	}
	fragment ButtonFields on ContentfulButton {
		__typename
		contentful_id
		text
		url
		variant
		color
		dataLayerEventName
		dataLayerEventAttributes {
			internal {
				content
			}
		}
		page {
			... on ContentfulPage {
				__typename
				contentful_id
				path
				name
			}
		}
		asset {
			... on ContentfulAsset {
				__typename
				contentful_id
				url
			}
		}
	}
	fragment TestimonialFields on ContentfulTestimonial {
		__typename
		id
		contentful_id
		headingText: heading
		body {
			childMarkdownRemark {
				rawMarkdownBody
				html
			}
		}
		attribution
	}
	fragment AccordionFields on ContentfulAccordion {
		__typename
		id
		contentful_id
		records {
			... on Node {
				... on ContentfulAccordionItem {
					title
					body {
						childMarkdownRemark {
							rawMarkdownBody
							html
						}
					}
				}
			}
		}
		faq
	}
	fragment HeadingFields on ContentfulHeading {
		__typename
		contentful_id
		name
		text
		level
		style
		logo {
			id
			gatsbyImageData(height: 96, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
		}
	}
	query LandingPageTemplateQuery($id: String) {
		page: contentfulPage(id: { eq: $id }) {
			contentful_id
			id
			name
			title
			slug
			path
			description
			canonicalUrl
			noindex
			nofollow
			titleForSitemap
			blocks {
				... on Node {
					... on ContentfulSection {
						__typename
						contentful_id
						id
						name
						heading {
							__typename
							contentful_id
							name
							text
							level
							style
							logo {
								id
								gatsbyImageData(height: 96, breakpoints: 3, quality: 100)
								publicUrl
								placeholderUrl
							}
						}
						body {
							childMarkdownRemark {
								rawMarkdownBody
								html
							}
						}
						blocks {
							... on Node {
								... on ContentfulAccordion {
									...AccordionFields
								}
								... on ContentfulButton {
									...ButtonFields
								}
								... on ContentfulCarousel {
									__typename
									id
									contentful_id
									name
								}
								... on ContentfulTabs {
									__typename
									id
									contentful_id
									name
									tabs {
										... on ContentfulTab {
											__typename
											id
											contentful_id
											name
											label
											content {
												... on ContentfulContentSection {
													__typename
													id
													contentful_id
													name
													actions {
														... on ContentfulLink {
															...LinkFields
														}
													}
													body {
														childMarkdownRemark {
															rawMarkdownBody
															html
														}
													}
													heading {
														__typename
														contentful_id
														name
														text
														level
														style
														logo {
															id
															gatsbyImageData(height: 96, quality: 100)
															publicUrl
															placeholderUrl
														}
													}
													image {
														id
														url
														localFile {
															childImageSharp {
																gatsbyImageData(
																	layout: CONSTRAINED
																	width: 900
																	quality: 90
																	aspectRatio: 1.778
																	placeholder: DOMINANT_COLOR
																)
															}
														}
													}
													imageAlt
													imageAlign
												}
											}
										}
									}
								}
								... on ContentfulTeam {
									__typename
									id
									contentful_id
									name
									members {
										... on ContentfulTeamMember {
											__typename
											id
											contentful_id
											name
											jobTitle
											body {
												childMarkdownRemark {
													rawMarkdownBody
													html
												}
											}
											image {
												publicUrl
												url
												gatsbyImageData(
													layout: CONSTRAINED
													width: 320
													quality: 85
													aspectRatio: 1
													placeholder: BLURRED
												)
											}
											thumbnail: image {
												publicUrl
												url
												gatsbyImageData(
													layout: CONSTRAINED
													width: 80
													height: 80
													quality: 65
													aspectRatio: 1
													placeholder: BLURRED
												)
											}
										}
									}
								}
								... on ContentfulContactUs {
									__typename
									id
									contentful_id
									name
									phone
									email
									address
									disclaimer
								}
								... on ContentfulContactForm {
									__typename
									id
									contentful_id
									name
									subject
								}
								... on ContentfulNewsFeatured {
									__typename
									id
									contentful_id
									name
								}
								... on ContentfulNewsLatest {
									__typename
									id
									contentful_id
									name
									dataLayerEventName
								}
								... on ContentfulNewsSearch {
									__typename
									id
									contentful_id
									name
								}
								... on ContentfulContentSection {
									__typename
									id
									contentful_id
									name
									actions {
										... on ContentfulLink {
											...LinkFields
										}
									}
									body {
										childMarkdownRemark {
											rawMarkdownBody
											html
										}
									}
									heading {
										__typename
										contentful_id
										name
										text
										level
										style
										logo {
											id
											gatsbyImageData(height: 96, quality: 100)
											publicUrl
											placeholderUrl
										}
									}
									image {
										id
										url
										localFile {
											childImageSharp {
												gatsbyImageData(
													layout: CONSTRAINED
													width: 900
													quality: 90
													aspectRatio: 1.778
													placeholder: DOMINANT_COLOR
												)
											}
										}
									}
									imageAlt
									imageAlign
								}
								... on ContentfulGrid {
									__typename
									id
									contentful_id
									name
									columns
									maxrows
									items {
										... on Node {
											... on ContentfulCard {
												__typename
												contentful_id
												title
												body {
													childMarkdownRemark {
														rawMarkdownBody
														html
													}
												}
												icon
											}
											... on ContentfulGridMedia {
												__typename
												id
												contentful_id
												title
												image {
													id
													url
													localFile {
														childImageSharp {
															gatsbyImageData(
																layout: CONSTRAINED
																width: 400
																height: 300
																quality: 80
																placeholder: DOMINANT_COLOR
															)
														}
													}
												}
												logo {
													id
													url
													localFile {
														childImageSharp {
															gatsbyImageData(
																layout: CONSTRAINED
																height: 160
																quality: 100
																placeholder: NONE
															)
														}
													}
												}
												description {
													childMarkdownRemark {
														rawMarkdownBody
														html
													}
												}
												links {
													... on Node {
														... on ContentfulLink {
															...LinkFields
														}
													}
												}
											}
											... on ContentfulTestimonial {
												...TestimonialFields
											}

											... on ContentfulAccordion {
												...AccordionFields
											}
											... on ContentfulArticle {
												__typename
												id
												contentful_id
											}
											... on ContentfulGrid {
												__typename
												id
												contentful_id
												items {
													... on Node {
														... on ContentfulCard {
															__typename
															contentful_id
															title
															body {
																childMarkdownRemark {
																	rawMarkdownBody
																	html
																}
															}
															icon
														}
													}
												}
											}
										}
									}
								}
								... on ContentfulTestimonial {
									...TestimonialFields
								}
								... on ContentfulTestimonialGrid {
									__typename
									id
									contentful_id
									name
									testimonials {
										... on Node {
											... on ContentfulTestimonial {
												...TestimonialFields
											}
										}
									}
								}
							}
						}
						actions {
							... on Node {
								... on ContentfulButton {
									...ButtonFields
								}
							}
						}
					}
					... on ContentfulHero {
						__typename
						contentful_id
						id
						name
						headingLight
						headingBold
						body {
							childMarkdownRemark {
								rawMarkdownBody
								html
							}
						}
						media {
							id
							# gatsbyImageData(width: 600, breakpoints: 3, quality: 80)
							# url
							# publicUrl
							# placeholderUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: FULL_WIDTH
										quality: 80
										placeholder: DOMINANT_COLOR
									)
								}
							}
						}
						actions {
							... on Node {
								... on ContentfulButton {
									...ButtonFields
								}
							}
						}
						mediaAlt
					}
					... on ContentfulFactBanner {
						__typename
						contentful_id
						id
						name
						heading {
							... on ContentfulHeading {
								...HeadingFields
							}
						}
						fact1Label
						fact1Value
						fact2Label
						fact2Value
						fact3Label
						fact3Value
					}
					... on ContentfulHeroPanel {
						__typename
						contentful_id
						id
						name
						panel1Image {
							id
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 800
										quality: 80
										placeholder: DOMINANT_COLOR
									)
								}
							}
						}
						panel1HeadingLight
						panel1HeadingBold
						panel2Image {
							id
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 800
										quality: 80
										placeholder: DOMINANT_COLOR
									)
								}
							}
						}
						panel2HeadingLight
						panel2HeadingBold
						panel3Image {
							id
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 800
										quality: 80
										placeholder: DOMINANT_COLOR
									)
								}
							}
						}
						panel3HeadingLight
						panel3HeadingBold
					}
					... on ContentfulPageHeader {
						__typename
						contentful_id
						id
						name
						title
					}
					... on ContentfulMarkdownBlock {
						__typename
						contentful_id
						id
						name
						body {
							childMarkdownRemark {
								rawMarkdownBody
								html
							}
						}
					}
					... on ContentfulNewsFeatured {
						__typename
						id
						contentful_id
						name
					}
					... on ContentfulNewsSearch {
						__typename
						id
						contentful_id
						name
					}
					... on ContentfulSitemap {
						__typename
						id
						contentful_id
						name
					}
				}
			}
		}
	}
`;
