/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenGrid,
	DrivenIcon,
	DrivenLink,
	DrivenTypography,
	IconSizes,
	IconTypes_FontAwesome,
} from '@iterateai/driven-components';
import * as React from 'react';

type ContactUsProps = {
	phone?: string;
	email?: string;
	address?: string;
	disclaimer?: string;
};

export default function Contactus({
	phone,
	email,
	address,
	disclaimer,
}: ContactUsProps) {
	return (
		<DrivenGrid container justifyContent='center' mb={1}>
			<DrivenGrid item md={8} lg={8}>
				<DrivenBox
					display='flex'
					flexDirection='column'
					flexWrap='nowrap'
					rowGap={3}
				>
					{phone && (
						<DrivenTypography
							variant='body1Bold'
							component='p'
							display='flex'
							alignItems='center'
							gap={1}
						>
							<DrivenIcon
								icon={IconTypes_FontAwesome.PHONE}
								size={IconSizes.MD}
							/>{' '}
							{phone}
						</DrivenTypography>
					)}

					{email && (
						<DrivenTypography
							variant='body1Bold'
							component='p'
							display='flex'
							alignItems='center'
							gap={1}
						>
							<DrivenIcon
								icon={IconTypes_FontAwesome.ENVELOPE}
								size={IconSizes.MD}
							/>{' '}
							<DrivenLink
								href={`mailto:${email}`}
								color='inherit'
								variant='body1Bold'
							>
								{email}
							</DrivenLink>
						</DrivenTypography>
					)}

					{address && (
						<DrivenTypography
							variant='body1Bold'
							component='p'
							display='flex'
							alignItems='center'
							gap={1}
						>
							<DrivenIcon
								icon={IconTypes_FontAwesome.BUILDINGS}
								size={IconSizes.MD}
							/>{' '}
							{address}
						</DrivenTypography>
					)}

					{disclaimer && (
						<DrivenTypography variant='body2' mt={6}>
							{disclaimer}
						</DrivenTypography>
					)}
				</DrivenBox>
			</DrivenGrid>
		</DrivenGrid>
	);
}
