import {
	DrivenBox,
	DrivenIcon,
	DrivenLink,
	DrivenTypography,
	IconSizes,
} from '@iterateai/driven-components';
import ContentfulHeading from './heading';
import { DrivenBrands, getTheme } from '@iterateai/driven-tokens';
import { styled, useTheme } from '@mui/material';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';

// interface CardProps {
// 	title?: string;
// 	body?: {
// 		childMarkdownRemark: {
// 			html: string;
// 			rawMarkdownBody?: string;
// 		};
// 	};
// 	icon?: IconTypes_FontAwesome;
// }

const StyledGridMediaHeader = styled(DrivenBox)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	margin: 0,
	color: theme.palette.common.white,

	'.GridMediaHeader__Overlay': {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		padding: theme.spacing(2),
		flexGrow: 0,
		display: 'flex',
		justifyContent: 'flexStart',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
	},

	'.GridMediaHeader__Overlay--Centered': {
		// Position heading in center of box
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},

	'.GridMediaHeader__Heading': {
		lineHeight: 1.25, // TODO fix this in the design system directly
	},

	'.GridMediaHeader__Logo': {
		maxWidth: '75%',
		maxHeight: '50%',
	},
}));

const GridMediaHeader = ({ title, image, logo }) => {
	const gatsbyImage = getImage(image.localFile.childImageSharp.gatsbyImageData);

	const gatsbyLogo = getImage(
		logo?.localFile?.childImageSharp?.gatsbyImageData
	);

	const centered = logo ? true : false;
	let overlayClassName = 'GridMediaHeader__Overlay';

	if (centered) {
		overlayClassName += ' GridMediaHeader__Overlay--Centered';
	}

	return (
		<StyledGridMediaHeader>
			<GatsbyImage image={gatsbyImage} alt='' objectFit='cover' />
			<DrivenBox className={overlayClassName}>
				{!logo && (
					<ContentfulHeading
						style='h5'
						level='h3'
						text={title}
						className='GridMediaHeader__Heading'
					/>
				)}

				{logo && (
					<GatsbyImage
						image={gatsbyLogo}
						alt={title}
						objectFit='contain'
						className='GridMediaHeader__Logo'
					/>
				)}
			</DrivenBox>
		</StyledGridMediaHeader>
	);
};

const StyledGridMediaFooter = styled(DrivenBox)(({ theme }) => ({
	'.GridMediaFooter__LinkList': {
		display: 'block',
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},

	'.GridMediaFooter__LinkListItem': {
		display: 'block',
		margin: `0 0 ${theme.spacing(0.5)} 0`,
		padding: 0,
	},

	'.GridMediaFooter__LinkListItem > *': {},
}));

const GridMediaFooter = ({ links }) => {
	if (links?.length) {
		const theme = useTheme();

		return (
			<StyledGridMediaFooter mt={1}>
				<ul className='GridMediaFooter__LinkList'>
					{links.map((link: any, i: number) => (
						<li
							className='GridMediaFooter__LinkListItem'
							key={`${link.name}-${i}`}
						>
							<DrivenLink
								href={link.url}
								// icon={<DrivenIcon icon='arrow-right-long' />}
								// iconPlacement='right'
								color={theme.palette.tertiary?.main}
								variant='body2Bold'
							>
								{link.text}{' '}
								<DrivenIcon icon='arrow-right-long' size={IconSizes.SM} />
							</DrivenLink>
						</li>
					))}
				</ul>
			</StyledGridMediaFooter>
		);
	}

	return null;
};

const StyledGridMedia = styled(DrivenBox)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	width: '100%',
	margin: 0,
	padding: 0,
}));

const GridMedia = ({ title, description, links, image, logo }: any) => {
	return (
		<StyledGridMedia>
			<GridMediaHeader title={title} image={image} logo={logo} />

			<DrivenBox>
				<DrivenTypography variant='body2' component='p' mt={2}>
					{description.childMarkdownRemark.rawMarkdownBody}
				</DrivenTypography>
			</DrivenBox>

			{links && <GridMediaFooter links={links} width='100%' />}
		</StyledGridMedia>
	);
};

export default GridMedia;
