/* eslint-disable react/prop-types */
import { DrivenBox } from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import * as React from 'react';

import { ComponentMatch } from '../hooks/component-match';
import { TestimonialProps } from './testimonial';

interface TestimonialGridProps {
	testimonials: Array<TestimonialProps>;
}

export default function TestimonialGrid({
	testimonials,
}: TestimonialGridProps) {
	const theme = useTheme();

	return (
		<DrivenBox
			display='grid'
			gap={3}
			gridTemplateColumns={{
				xs: '1fr',
				md: '1fr 1fr',
			}}
		>
			{testimonials && (
				<React.Fragment>{ComponentMatch(testimonials)}</React.Fragment>
			)}
		</DrivenBox>
	);
}
