import {
	DrivenBox,
	DrivenContainer,
	DrivenLink,
	DrivenTypography,
} from '@iterateai/driven-components';
import { useTheme, useMediaQuery } from '@mui/material';
import { StaticQuery, graphql } from 'gatsby';
import useLinkHref from '../hooks/use-link-href';
import * as React from 'react';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';

const Sitemap = ({}) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					Pages: allContentfulPage(filter: { node_locale: { eq: "en-US" } }) {
						nodes {
							title
							titleForSitemap
							path
						}
					}
					Articles: allContentfulArticle(
						filter: { node_locale: { eq: "en-US" } }
						sort: { fields: date, order: DESC }
					) {
						nodes {
							headline
							slug
						}
					}
				}
			`}
			render={(data) => {
				const { Pages, Articles } = data;

				const theme = useTheme();
				const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

				const compareTitle = (a: any, b: any) => {
					if (a.title < b.title) {
						return -1;
					}
					if (a.title > b.title) {
						return 1;
					}
					return 0;
				};

				return (
					<DrivenContainer>
						<DrivenBox py={{ xs: 3, md: 4, lg: 6 }}>
							<DrivenTypography variant='h6' component='h2'>
								Pages
							</DrivenTypography>
							<DrivenBox component='ul' pl={3}>
								{Pages.nodes
									.map((page: any) => {
										page.title = page.titleForSitemap
											? page.titleForSitemap
											: page.title;

										return page;
									})
									.filter((page: any) => {
										if (page.path === '/sitemap') {
											return false;
										}
										return true;
									})
									.sort(compareTitle)
									.map((page: any, i: number) => (
										<DrivenBox
											component='li'
											ml={-1}
											mb={0.5}
											pl={0.25}
											key={`sitemap-page-${i}`}
										>
											<DrivenLink
												variant='body1Bold'
												color='inherit'
												href={page.path}
												LinkComponent={GatsbyLink}
											>
												{page.title}
											</DrivenLink>
										</DrivenBox>
									))}
							</DrivenBox>
						</DrivenBox>

						<DrivenTypography variant='h6' component='h2'>
							Driven Brands News
						</DrivenTypography>
						<DrivenBox component='ul' pl={3}>
							{Articles.nodes.map((article: any, i: number) => (
								<DrivenBox
									component='li'
									ml={-1}
									mb={0.5}
									pl={0.25}
									key={`sitemap-article-${i}`}
								>
									<DrivenLink
										variant='body1Bold'
										color='inherit'
										href={`/news/${article.slug}/`}
										LinkComponent={GatsbyLink}
									>
										{article.headline}
									</DrivenLink>
								</DrivenBox>
							))}
						</DrivenBox>
					</DrivenContainer>
				);
			}}
		/>
	);
};

export default Sitemap;
