import * as React from 'react';

import Fallback from '../components/fallback';
import * as Components from '../components/sections';

type ComponentProps = {
	brand?: string;
	brandColor?: string;
	id: string | number;
	__typename: string;
};

export const ComponentMatch = (components?: any) => {
	return (
		components &&
		components?.map((component: ComponentProps, index: number) => {
			const { id, __typename } = component;
			const Component = Components[__typename] || Fallback;
			component.brand = 'DrivenBrands';

			if (!Component) {
				return;
			}
			// console.log(`${id} ${__typename} ${index}`);
			return <Component key={`${id} ${__typename} ${index}`} {...component} />;
		})
	);
};
