/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenDropdown,
	DrivenIcon,
	DrivenTextInput,
} from '@iterateai/driven-components';
import { SelectChangeEvent, useTheme } from '@mui/material';
import * as React from 'react';
import { useArticles } from '../hooks/use-articles';
import Grid from './grid';
import ArticleCard from './article-card';

interface NewsLatestProps {}

export default function NewsLatest({}: NewsLatestProps) {
	const theme = useTheme();
	const { allArticles } = useArticles();

	const yearOptions = Array.from(
		new Set(allArticles.map((article: any) => article.dateYear))
	)
		.sort()
		.reverse()
		.map((year) => ({
			value: year,
			option: year,
		}));

	yearOptions.unshift({
		value: 'all',
		option: 'All Years',
	});

	const [selectedYear, setSelectedYear] = React.useState('all');
	const [titleSearch, setTitleSearch] = React.useState('');

	const handleYearChange = (event: SelectChangeEvent<unknown>) => {
		setSelectedYear(event.target.value as string);
	};

	const handleTitleSearchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTitleSearch(event.target.value as string);
	};

	const filteredArticles = allArticles.filter((article: any) => {
		if (selectedYear !== 'all') {
			if (article.dateYear !== selectedYear) {
				return false;
			}
		}

		if (titleSearch !== '') {
			if (!article.headline.toLowerCase().includes(titleSearch.toLowerCase())) {
				return false;
			}
		}

		return true;
	});

	return (
		<DrivenBox>
			<DrivenBox
				display='grid'
				gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
				columnGap={3}
				rowGap={2}
				mb={3}
			>
				<DrivenBox
					sx={{
						// Match search input heigh & alignment to the select dropdown
						'*': {
							boxSizing: 'border-box !important',
						},
						'.MuiFormControl-root': {
							marginTop: 0,
						},
						'.MuiOutlinedInput-root, .MuiOutlinedInput-input': {
							height: '56px',
						},
						label: {
							paddingY: theme.spacing(1),
						},
						i: {
							marginRight: theme.spacing(1),
						},
					}}
				>
					<DrivenTextInput
						icon={<DrivenIcon icon='magnifying-glass' />}
						iconPlacement='right'
						label='Search Article Titles'
						height='48px'
						value={titleSearch}
						onChange={(event) => handleTitleSearchChange(event)}
					/>
				</DrivenBox>

				<DrivenBox>
					<DrivenDropdown
						label='Year Published'
						options={yearOptions}
						value={selectedYear}
						onChange={(event) => handleYearChange(event)}
						sx={{
							// Match search input height to the select dropdown
							'*': {
								boxSizing: 'border-box',
							},
						}}
					/>
				</DrivenBox>
			</DrivenBox>

			<Grid columns={4} maxrows={2}>
				{filteredArticles?.map((article: any) => (
					<ArticleCard
						article={article}
						key={`latest-articles-grid-${article.id}`}
					/>
				))}
			</Grid>
		</DrivenBox>
	);
}
