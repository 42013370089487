import {
	DrivenBox,
	DrivenButton,
	DrivenDropdown,
	DrivenGrid,
	DrivenStack,
	DrivenTextInput,
	DrivenTypography,
} from '@iterateai/driven-components';
import { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ContactFormProps } from '../';

export const AccessibilityContactForm = React.memo(
	function AccessibilityContactForm({ supportText }: ContactFormProps) {
		const {
			formState: { errors, isDirty },
			register,
		} = useFormContext();

		React.useEffect(() => {}, [isDirty]);

		return (
			<DrivenBox>
				<DrivenStack>
					<DrivenTypography variant='body1' component='div'>
						{supportText}
					</DrivenTypography>
				</DrivenStack>

				<DrivenGrid container direction='column'>
					<DrivenGrid container item xs columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<DrivenGrid item xs={12} sm={6}>
							<DrivenTextInput
								label='First Name *'
								hasError={Boolean(errors['firstName'])}
								errorHelperText={errors['firstName']?.message as string}
								required
								{...register('firstName')}
							/>
						</DrivenGrid>
						<DrivenGrid item xs={12} sm={6}>
							<DrivenTextInput
								label='Last Name *'
								hasError={Boolean(errors['lastName'])}
								errorHelperText={errors['lastName']?.message as string}
								required
								{...register('lastName')}
							/>
						</DrivenGrid>
					</DrivenGrid>

					<DrivenGrid item xs>
						<DrivenTextInput
							label='Email Address *'
							hasError={Boolean(errors['email'])}
							errorHelperText={errors['email']?.message as string}
							required
							{...register('email')}
						/>
					</DrivenGrid>

					<DrivenGrid item xs>
						<DrivenTextInput
							label='Your Message *'
							minRows={4}
							multiline
							sx={{
								height: 'auto',
								'& .MuiInputBase-inputMultiline': {
									border: 'none',
									py: 2,
									px: 1.75,
									typography: 'body1',
									width: '100%',
								},
							}}
							hasError={Boolean(errors['comments'])}
							errorHelperText={errors['comments']?.message as string}
							{...register('comments')}
						/>
					</DrivenGrid>
				</DrivenGrid>
			</DrivenBox>
		);
	}
);
