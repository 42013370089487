/* eslint-disable react/prop-types */
import * as React from 'react';
import { useArticles } from '../hooks/use-articles';
import Grid from './grid';
import ArticleCard from './article-card';

interface NewsLatestProps {
	dataLayerEventName?: string;
}

export default function NewsLatest({
	dataLayerEventName = '',
}: NewsLatestProps) {
	const { latestArticles } = useArticles();

	return (
		<Grid columns={4}>
			{latestArticles?.map((article: any) => (
				<ArticleCard
					article={article}
					key={`latest-articles-grid-${article.id}`}
					dataLayerEventName={
						dataLayerEventName ? dataLayerEventName : undefined
					}
				/>
			))}
		</Grid>
	);
}
