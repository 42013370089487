/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenGrid,
	DrivenTypography,
} from '@iterateai/driven-components';
import { useTheme } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useArticles } from '../hooks/use-articles';
import Heading from './heading';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';

const ArticleThumbnail: any = ({
	image,
	alt,
}: {
	image?: any;
	alt?: string;
}) => {
	const gatsbyImage = getImage(
		image?.localFile?.childImageSharp.gatsbyImageData
	);

	return <GatsbyImage image={gatsbyImage} alt={alt || ''} />;
};

interface NewsFeaturedProps {}

export default function NewsFeatured({}: NewsFeaturedProps) {
	const theme = useTheme();
	const { latestArticle } = useArticles();

	return (
		<DrivenContainer component='article'>
			<DrivenGrid
				container
				flexDirection={{ xs: 'column-reverse', md: 'row' }}
				my={{ xs: 3, sm: 4, md: 6 }}
			>
				<DrivenGrid item md={latestArticle.image ? 6 : 12}>
					<DrivenBox marginRight={{ md: latestArticle.image ? 3 : 0 }}>
						<DrivenBox mb={3}>
							<Heading level='h2' style='subtitle3' text='Featured Article' />
						</DrivenBox>
						<Heading
							level='h3'
							style='h4'
							text={latestArticle.headline}
							id={`featured-news-${latestArticle.id}`}
						/>
						<DrivenTypography variant='subtitle2' component='p' mt={3}>
							{latestArticle.subheadline}
						</DrivenTypography>

						<DrivenBox mt={6}>
							<DrivenButton
								variant='contained'
								color='primary'
								href={latestArticle.path}
								aria-labelledby={`featured-news-${latestArticle.id}`}
								LinkComponent={GatsbyLink}
							>
								Read More
							</DrivenButton>
						</DrivenBox>
					</DrivenBox>
				</DrivenGrid>

				{latestArticle.image?.localFile && (
					<DrivenGrid item md={6}>
						<ArticleThumbnail
							image={latestArticle.image}
							alt={latestArticle.imageAlt}
						/>
					</DrivenGrid>
				)}
			</DrivenGrid>
		</DrivenContainer>
	);
}
