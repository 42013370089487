import { useStaticQuery, graphql } from 'gatsby';

const articleSlugToPath = (article) => {
	article.path = `/news/${article.slug}/`;
	return article;
};

export const useArticles = () => {
	const { allArticles, latestArticles, latestArticle } = useStaticQuery(
		graphql`
			fragment ArticleCardFields on ContentfulArticle {
				id
				contentful_id
				slug
				headline
				subheadline

				# Formatted for datetime attribute of <time> element
				dateStructured: date(formatString: "YYYY-MM-DD")

				# Formatted for displaying date to users
				dateFormatted: date(formatString: "MMMM DD, YYYY")

				# Just the year, for creating a list of years to filter by
				dateYear: date(formatString: "YYYY")

				image {
					id
					url
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 600
								height: 450
								quality: 80
								placeholder: DOMINANT_COLOR
							)
						}
					}
				}
				imageAlt
			}
			query {
				allArticles: allContentfulArticle(
					filter: { node_locale: { eq: "en-US" } }
					sort: { fields: date, order: DESC }
				) {
					nodes {
						...ArticleCardFields
					}
				}
			}
		`
	);

	const articles = {};

	// Convert article slugs into url paths, and slice into each use case
	articles.allArticles = allArticles?.nodes
		? allArticles.nodes.map((article) => articleSlugToPath(article))
		: [];

	articles.latestArticles = allArticles?.nodes
		? allArticles.nodes.map((article) => articleSlugToPath(article)).slice(0, 4)
		: [];

	articles.latestArticle = allArticles?.nodes
		? articleSlugToPath(allArticles.nodes[0])
		: [];

	return articles;
};
