/* eslint-disable react/prop-types */
import {
	ButtonIconPlacement,
	DrivenBox,
	DrivenButton,
	IconTypes_FontAwesome,
} from '@iterateai/driven-components';
import { ComponentMatch } from '../hooks/component-match';
import * as React from 'react';
import { BoxProps, styled } from '@mui/material';

interface GridProps {
	items?: Array<any>;
	columns: number;
	maxrows?: number | null;
	children?: React.ReactNode;
}

const truncatedItemsSelector = ({
	columns,
	maxrows,
}: {
	columns: number;
	maxrows: number;
}) => `&[data-hide-overflow] > :nth-of-type(n+${columns * maxrows + 1})`;

const StyledGrid = styled(DrivenBox)(({ theme, columns, maxrows }) => {
	const truncatedItemsSelectors: { xs: string; sm: string; md: string } = {
		xs: truncatedItemsSelector({ columns: 1, maxrows }),
		sm: truncatedItemsSelector({ columns: 2, maxrows }),
		md: truncatedItemsSelector({ columns: 4, maxrows }),
	};

	return {
		display: 'grid',
		gap: theme.spacing(3),

		// Force children to use flexbox to match heights
		'& > *': {
			display: 'flex',
		},

		[theme.breakpoints.only('xs')]: {
			// gridTemplateColumns: '1fr',
			gridTemplateColumns: 'repeat(auto-fit, minmax(218px, 1fr))',

			[`${truncatedItemsSelectors.xs}`]: {
				display: 'none',
			},
		},

		[theme.breakpoints.only('sm')]: {
			// gridTemplateColumns: 'repeat(2, 1fr)',
			gridTemplateColumns: 'repeat(auto-fit, minmax(218px, 1fr))',

			[`${truncatedItemsSelectors.sm}`]: {
				display: 'none',
			},
		},

		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: `repeat(${columns}, 1fr)`,

			[`${truncatedItemsSelectors.md}`]: {
				display: 'none',
			},
		},
	};
});

export default function Grid({
	items,
	columns = 3,
	maxrows = null,
	children,
}: GridProps & BoxProps) {
	const gridItems = items
		? items
		: children
		? React.Children.toArray(children)
		: [];
	const showExpandButton: boolean =
		maxrows === null
			? false
			: gridItems && gridItems.length > columns * maxrows;
	const [isExpanded, setExpanded] = React.useState(false);
	const dataAttrs: any = {};

	if (showExpandButton && !isExpanded) {
		dataAttrs['data-hide-overflow'] = true;
	}

	return (
		<DrivenBox>
			<StyledGrid columns={columns} maxrows={maxrows} {...dataAttrs}>
				{items && <React.Fragment>{ComponentMatch(items)}</React.Fragment>}
				{!items && children && <React.Fragment>{children}</React.Fragment>}
			</StyledGrid>

			{showExpandButton && (
				<DrivenBox display='flex' justifyContent='center' mt={6}>
					<DrivenButton
						variant='outlined'
						color='inherit'
						icon={
							isExpanded
								? IconTypes_FontAwesome.CHEVRON_UP
								: IconTypes_FontAwesome.CHEVRON_DOWN
						}
						iconPlacement={ButtonIconPlacement.RIGHT}
						onClick={() => {
							setExpanded(!isExpanded);
						}}
					>
						{isExpanded ? 'View Less' : 'View More'}
					</DrivenButton>
				</DrivenBox>
			)}
		</DrivenBox>
	);
}
