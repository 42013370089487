/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenButton,
	DrivenButtonProps,
	DrivenContainer,
	DrivenGrid,
	DrivenTypography,
} from '@iterateai/driven-components';
import { navigate } from 'gatsby';
import * as React from 'react';
import Heading, { HeadingProps } from './heading';
import ContentfulButton from './button';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';
import { getBrandImage, getBrand } from '../utils/branding';
import MarkdownContent from '../components/markdown-content';
import { ComponentMatch } from '../hooks/component-match';
import useLinkHref from '../hooks/use-link-href';

type Link = {
	href: string;
	text: string;
};

interface SectionProps {
	name: string;
	inline?: string; // TODO look into this option?
	heading?: any; // TODO create heading component
	body: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	blocks: Array<any>;
	actions: Array<DrivenButtonProps>; // TODO currently in Contentful as a single-reference field, not multiple.
	backgroundColor?: string;
}

export const Section = ({
	heading,
	body,
	blocks,
	actions,
	backgroundColor,
}: SectionProps) => {
	return (
		<DrivenBox>
			<DrivenContainer maxWidth='lg'>
				<DrivenBox py={6}>
					<DrivenGrid container justifyContent='center'>
						<DrivenGrid item lg={8}>
							<DrivenBox textAlign='center' mb={3}>
								<Heading {...heading} />
							</DrivenBox>
						</DrivenGrid>
					</DrivenGrid>

					{body && (
						<DrivenGrid container justifyContent='center' mb={1}>
							<DrivenGrid item md={8} lg={8}>
								<DrivenBox>
									<MarkdownContent>
										{body.childMarkdownRemark.rawMarkdownBody}
									</MarkdownContent>
								</DrivenBox>
							</DrivenGrid>
						</DrivenGrid>
					)}

					{blocks && <React.Fragment>{ComponentMatch(blocks)}</React.Fragment>}

					{actions && (
						<DrivenBox
							display='flex'
							justifyContent='center'
							flexWrap='wrap'
							gap={3}
							mt={6}
						>
							{actions.map((action, i) => (
								<ContentfulButton {...action} key={`${action.name}-${i}`} />
							))}
						</DrivenBox>
					)}
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
};

export default Section;
