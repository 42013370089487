/* eslint-disable react/prop-types */
import {
	DrivenBox,
	DrivenContainer,
	DrivenTypography,
} from '@iterateai/driven-components';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import Heading, { HeadingProps } from './heading';

import * as React from 'react';

interface FactBannerProps {
	heading: HeadingProps;
	fact1Label: string;
	fact1Value: string;
	fact2Label: string;
	fact2Value: string;
	fact3Label: string;
	fact3Value: string;
}

const Fact: any = ({ label, value }: { label: string; value: string }) => {
	const theme = useTheme();
	const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<DrivenBox
			display='flex'
			flexDirection='column-reverse'
			alignItems={{
				xs: 'center',
				lg: 'flex-start',
			}}
		>
			<DrivenTypography
				variant={isTabletOrDesktop ? 'subtitle1' : 'body1'}
				component='dt'
				color={theme.palette.common.white}
				mt={{ xs: 0.5, md: 1 }}
				whiteSpace='nowrap'
			>
				{label}
			</DrivenTypography>

			<DrivenTypography
				variant='h2Bold'
				component='dd'
				color={theme.palette.common.white}
				sx={{
					[theme.breakpoints.down('md')]: {
						fontSize: '36px',
						fontWeight: '600',
					},
				}}
			>
				{value}
			</DrivenTypography>
		</DrivenBox>
	);
};

export default function FactBanner({
	heading,
	fact1Label,
	fact1Value,
	fact2Label,
	fact2Value,
	fact3Label,
	fact3Value,
}: FactBannerProps) {
	const theme = useTheme();

	// Special case handling of line breaks. Will only apply when
	// the heading is "the facts about driven brands"
	// Any other heading text will be at the mercy of the browser
	// unless this component is modified to match.
	const headingTextHtml =
		heading.text.toLowerCase() === 'the facts about driven brands' ? (
			<React.Fragment>
				<span>The Facts</span> <span>About Driven Brands</span>
			</React.Fragment>
		) : (
			heading.text
		);

	return (
		<DrivenBox
			sx={{
				backgroundColor: theme.palette.common.black,
			}}
		>
			<DrivenContainer maxWidth='lg'>
				<DrivenBox
					py={{ xs: 3, sm: 4, md: 6 }}
					display='flex'
					flexDirection={{
						xs: 'column',
						lg: 'row',
					}}
					gap={3}
					flexWrap='nowrap'
					alignItems={{
						lg: 'center',
					}}
					color={theme.palette.grey['400']}
					textAlign={{
						xs: 'center',
						lg: 'left',
					}}
				>
					<Heading
						level='h2'
						style='h3'
						text={headingTextHtml}
						sx={{
							span: {
								display: 'inline-block',
							},
						}}
					/>

					<DrivenBox
						component='dl'
						display='flex'
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						justifyContent={{
							sm: 'center',
						}}
						gap={{
							xs: 3,
							sm: 6,
						}}
						margin={0}
						padding={0}
					>
						<Fact label={fact1Label} value={fact1Value} />
						<Fact label={fact2Label} value={fact2Value} />
						<Fact label={fact3Label} value={fact3Value} />
					</DrivenBox>
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
}
