/* eslint-disable react/prop-types */
import { DrivenBox, DrivenTypography } from '@iterateai/driven-components';
import { styled, useTheme } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

type StyledHeadingProps = {
	light?: string;
	bold?: string;
};

type HeroPanelProps = {
	panel1Image: any;
	panel1HeadingLight: string;
	panel1HeadingBold: string;
	panel2Image: any;
	panel2HeadingLight: string;
	panel2HeadingBold: string;
	panel3Image: any;
	panel3HeadingLight: string;
	panel3HeadingBold: string;
};

interface PanelProps {
	headingLight?: string;
	headingBold?: string;
	image?: any;
}

const LightHeading = styled('span')(() => ({
	display: 'block',
	fontWeight: '400',
}));

const BoldHeading = styled('span')(() => ({
	display: 'block',
	fontWeight: '700',
}));

const StyledHeading = ({ light, bold }: StyledHeadingProps) => (
	<span>
		<LightHeading>{light}</LightHeading> <BoldHeading>{bold}</BoldHeading>
	</span>
);

const BackgroundImage = styled(GatsbyImage)(() => ({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,

	'&:after': {
		content: '""',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundImage:
			'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0.4) 100%)',
	},
}));

const StyledBackgroundImage = ({ image }: any) => (
	<BackgroundImage image={image} alt='' loading='eager' />
);

const Panel = ({ headingLight, headingBold, image }: PanelProps) => {
	const theme = useTheme();
	const heroBg = getImage(image?.localFile.childImageSharp.gatsbyImageData);

	return (
		<DrivenBox
			display='flex'
			alignItems='flex-end'
			p={{
				xs: 2,
				lg: 4,
			}}
			position='relative'
		>
			{heroBg && <StyledBackgroundImage image={heroBg} />}

			<DrivenBox position='relative'>
				<DrivenTypography
					variant='h2'
					component='h2'
					color='white'
					sx={{
						[theme.breakpoints.only('md')]: {
							fontSize: '40px',
						},
					}}
				>
					<StyledHeading light={headingLight} bold={headingBold} />
				</DrivenTypography>
			</DrivenBox>
		</DrivenBox>
	);
};

export default function HeroPanel({
	panel1Image,
	panel1HeadingLight,
	panel1HeadingBold,
	panel2Image,
	panel2HeadingLight,
	panel2HeadingBold,
	panel3Image,
	panel3HeadingLight,
	panel3HeadingBold,
}: HeroPanelProps) {
	return (
		<DrivenBox
			display='grid'
			gridTemplateColumns={{
				xs: '85vw 85vw 85vw',
				sm: '66vw 66vw 66vw',
				md: '1fr 1fr 1fr',
			}}
			height={{
				xs: '240px',
				sm: '50vw',
				md: '384px',
				lg: '480px',
			}}
			sx={{
				overflowX: 'auto',
				scrollSnapType: 'x mandatory',

				'> *': {
					scrollSnapAlign: 'start',
				},

				/* Hide scrollbars */
				'scrollbar-width': 'none' /* Firefox */,
				'-ms-overflow-style': 'none' /* IE 10+ */,

				'&::-webkit-scrollbar': {
					background: 'transparent' /* Chrome/Safari/Webkit */,
					width: '0px',
				},
			}}
		>
			<Panel
				image={panel1Image}
				headingLight={panel1HeadingLight}
				headingBold={panel1HeadingBold}
			/>
			<Panel
				image={panel2Image}
				headingLight={panel2HeadingLight}
				headingBold={panel2HeadingBold}
			/>
			<Panel
				image={panel3Image}
				headingLight={panel3HeadingLight}
				headingBold={panel3HeadingBold}
			/>
		</DrivenBox>
	);
}
