import {
	DrivenAlert,
	DrivenBox,
	DrivenGrid,
	DrivenTypography,
} from '@iterateai/driven-components';
import React, { useCallback, useState } from 'react';
import {
	captureCustomDataLayerEvent,
	GatsbyLinkBehavior as GatsbyLink,
} from '../components/gatsby-link-behavior';
import useIsClient from '../hooks/use-is-client';

import { ContactFormWrapper } from './forms';

const ContactForm = ({
	subject,
}: {
	subject: 'Center' | 'Glass Center' | 'Car Wash' | 'Accessibility';
}) => {
	const [showFormConfirmation, setShowFormConfirmation] = useState(false);
	const [alertProps, setAlertProps] = useState({});
	const [resetForm, setResetForm] = useState(false);

	const { isClient } = useIsClient();

	let apiPathFragment: string,
		successMessage: string,
		successEvent: string = '';

	switch (subject) {
		case 'Center':
			apiPathFragment = 'center';
			successEvent = 'sell_your_center_form_submit';
			break;

		case 'Car Wash':
			apiPathFragment = 'carwash';
			successEvent = 'sell_your_car_wash_form_submit';
			break;

		case 'Glass Center':
			apiPathFragment = 'glass';
			successEvent = 'sell_your_glass_center_form_submit';
			break;

		case 'Accessibility':
			apiPathFragment = 'accessibility';
			successEvent = 'accessibility_form_submit';
			break;

		default:
			break;
	}

	const renderSubmissionAlert = useCallback(
		(formSuccess: any) => {
			// Error
			if (!Boolean(formSuccess)) {
				setAlertProps({
					severity: 'error',
					message:
						'Something went wrong. Please try submitting your form later.',
				});
				// Success
			} else {
				setAlertProps({
					severity: 'success',
					message: 'Thank you for your inquiry! We will contact you shortly.',
				});
			}
			setShowFormConfirmation(true);
		},
		[alertProps]
	);

	const submitForm = async (formData: any) => {
		if (resetForm) {
			setResetForm(false);
			setShowFormConfirmation(false);
		}
		await fetch(
			`${process.env['GATSBY_CONTACT_FORM_API_URL']}/${apiPathFragment}`,
			{
				method: 'POST',
				headers: {
					apikey: process.env['GATSBY_CONTACT_FORM_API_KEY'],
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			}
		)
			.then(({ ok, status }) => {
				const hasError = !ok || status !== 201;
				renderSubmissionAlert(hasError ? false : true);
				setResetForm(hasError ? false : true);
				if (!hasError) {
					captureCustomDataLayerEvent(successEvent, {});
				}
			})
			.catch((error) => {
				renderSubmissionAlert(false);
				// setResetForm(false);
			});
	};

	if (!isClient) return null;

	return (
		<DrivenGrid container>
			<DrivenGrid item md={8} mx='auto'>
				<ContactFormWrapper
					formOption={subject}
					handleFormSubmit={submitForm}
					resetValues={resetForm}
				/>
				<DrivenBox
					mt={3}
					sx={{
						'.MuiAlert-root': {
							alignItems: 'center',
						},
					}}
				>
					<DrivenAlert open={showFormConfirmation} {...alertProps}>
						{alertProps.message && (
							<DrivenTypography variant='body1'>
								{alertProps.message}
							</DrivenTypography>
						)}
					</DrivenAlert>
				</DrivenBox>
			</DrivenGrid>
		</DrivenGrid>
	);
};

export default ContactForm;
