/* eslint-disable react/prop-types */
import { DrivenBox, DrivenContainer } from '@iterateai/driven-components';
import MarkdownContent from '../components/markdown-content';
import * as React from 'react';

interface MarkdownBlockProps {
	body: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
}

export default function MarkdownBlock({ body }: MarkdownBlockProps) {
	return (
		<DrivenContainer>
			<DrivenBox mt={{ xs: 3, sm: 4, md: 6 }}>
				<MarkdownContent>
					{body.childMarkdownRemark.rawMarkdownBody}
				</MarkdownContent>
			</DrivenBox>
		</DrivenContainer>
	);
}
