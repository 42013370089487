/* eslint-disable react/prop-types */
import { DrivenBox, DrivenTabs, TextSize } from '@iterateai/driven-components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { useTheme } from '@mui/material';
import * as React from 'react';

import { ComponentMatch } from '../hooks/component-match';

interface TeamProps {}

const TeamMemberThumbnail: any = ({ image }: { image?: any }) => {
	const gatsbyImage = getImage(image.gatsbyImageData);

	return (
		<DrivenBox
			width='64px'
			height='64px'
			mb={0.5}
			sx={{ img: { borderRadius: '50%' } }}
		>
			<GatsbyImage image={gatsbyImage} alt='' width='80px' height='80px' />
		</DrivenBox>
	);
};

export default function Team({ members }: TeamProps) {
	const [value, setValue] = React.useState(0);
	const theme = useTheme();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const tabs = members.map((member) => ({
		label: member.name,
		icon: <TeamMemberThumbnail image={member.thumbnail} />,
		tabPanelContent: (
			<React.Fragment>{ComponentMatch([member])}</React.Fragment>
		),
	}));

	return (
		<React.Fragment>
			{tabs && (
				<DrivenTabs
					tabs={tabs}
					tabVariant={TextSize.small}
					value={value}
					onChange={handleChange}
					textColor='inherit'
					// // Horizontal scrolling for tabs.
					// // TODO Edit DS component to not apply "center" alignment
					// // when scrollable
					variant='scrollable'
					scrollButtons='auto'
					sx={{
						// Make names easier to read
						'.MuiButtonBase-root': {
							'.MuiTypography-root': {
								fontSize: '14px',
								fontWeight: 'normal',
								textTransform: 'none',
							},
						},

						'.MuiTabs-indicator': {
							backgroundColor: theme.palette.common.black,
						},
					}}
				/>
			)}
		</React.Fragment>
	);
}
