import {
	CardContentAlignment,
	DrivenIconCard,
	IconSizes,
	IconTypes_FontAwesome,
} from '@iterateai/driven-components';
import { DrivenBrands, getTheme } from '@iterateai/driven-tokens';
import MarkdownContent from './markdown-content';
import { styled, useTheme } from '@mui/material';
import * as React from 'react';

import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';

interface CardProps {
	title?: string;
	body?: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	icon?: IconTypes_FontAwesome;
}

const StyledDrivenIconCard = styled(DrivenIconCard)(({ theme }) => ({
	width: '100%',
	margin: 0,
	padding: 0,
	boxShadow: 'none',

	// Set icon to use the brand color, while heading text uses black
	i: {
		color: theme.palette.primary.main,
	},

	'.MuiCardHeader-root': {
		marginTop: 0,
		marginBottom: theme.spacing(0.5),
	},

	'.MuiCardContent-root': {
		padding: 0,
	},

	'.MuiTypography-paragraph': {
		marginTop: 0,
		paddingTop: 0,
	},
}));

const Card = ({ title, body, icon }: CardProps) => {
	const theme = useTheme();

	return (
		<StyledDrivenIconCard
			cardContentProps={{
				variant: 'body1',
			}}
			cardHeaderProps={{
				titleTypographyProps: theme.typography.h5,
				component: 'h3',
				sx: {
					padding: 0,
					color: theme.palette.common.black,
				},
			}}
			dense={true}
			icon={icon}
			contentAlignment={CardContentAlignment.LEFT}
			iconSize={IconSizes.XL}
			title={title}
			sx={{}}
		>
			<MarkdownContent>
				{body?.childMarkdownRemark.rawMarkdownBody}
			</MarkdownContent>
		</StyledDrivenIconCard>
	);
};

export default Card;
