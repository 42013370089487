/* eslint-disable react/prop-types */
import { DrivenButton, DrivenButtonProps } from '@iterateai/driven-components';
import { styled } from '@mui/material';
import {
	captureCustomDataLayerEvent,
	GatsbyLinkBehavior as GatsbyLink,
} from './gatsby-link-behavior';
import useLinkHref from '../hooks/use-link-href';
import * as React from 'react';

export type ContentfulButtonProps = Omit<DrivenButtonProps, 'color'> & {
	text?: string;
	page?: any;
	url?: string;
	asset?: any;
	color?:
		| 'default'
		| 'inherit'
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| 'tertiary'
		| 'quaternary';
	dataLayerEventName?: string;
	dataLayerEventAttributes?: any;
};

const StyledDrivenButton = styled(DrivenButton)(({}) => ({
	flexShrink: '0',
}));

const ContentfulButton = ({
	name,
	text,
	page,
	url,
	asset,
	variant,
	color,
	icon,
	iconPlacement,
	fullWidth,
	disabled,
	dataLayerEventName,
	dataLayerEventAttributes,
	...props
}: ContentfulButtonProps) => {
	const href = useLinkHref({ page, url, asset });

	// In Contentful, 'default' is presented to users instead of 'inherit'
	// So we map that back to inherit for a black button
	const buttonColor = !color || color === 'default' ? 'inherit' : color;

	const customDataLayerEventAttributes = dataLayerEventAttributes
		? JSON.parse(dataLayerEventAttributes.internal.content)
		: {};

	return (
		<StyledDrivenButton
			href={href}
			variant={variant}
			color={buttonColor}
			icon={icon}
			iconPlacement={iconPlacement}
			fullWidth={fullWidth}
			disabled={disabled}
			LinkComponent={GatsbyLink}
			onClick={
				dataLayerEventName
					? (e) => {
							captureCustomDataLayerEvent(
								dataLayerEventName,
								customDataLayerEventAttributes
							);
					  }
					: undefined
			}
		>
			{text}
		</StyledDrivenButton>
	);
};

export default ContentfulButton;
